<template>
  <div :class="isOpen ? ' open' : ''" class="quick-view" @click.self="closePopup()">
    <div class="quick-view-content" style="width: 1200px" v-if="isOpen">
      <div class="bg-white shadow header-list">
        <div class="p-2 d-flex align-items-center">
          <h5 class="m-0 font-16 bold mr-2">Thanh toán</h5>
          <options :disabled="disabledSelect" v-model="subjectName" :list="[
            { name: 'Một đơn hàng', key: 'ORDER' },
            { name: 'Nhiều đơn hàng', key: 'ORDER_LIST' },
            { name: 'Khách hàng', key: 'CUSTOMER' },
            { name: 'Cty khách hàng', key: 'COMPANY_CUSTOMER' },
          ]" @change="getOrders()" />

          <div class="flex-fill" />
          <button class="btn btn-primary no-break" :disabled="sending ||
            data.totalMoney < data.totalPayment ||
            data.totalPayment <= 0
            " @click="runPayment()">
            <i class="fa fa-save" />
            Lưu thông tin thanh toán
          </button>

          <button @click="closePopup()" type="button" class="btn btn-sm btn-basic ml-2">
            Tắt
          </button>
        </div>

        <div class="tabs-border mt-0 flex flex-row align-items-end">
          <div class="tab" @click="tabActive = 'form'" :class="{ active: tabActive == 'form' }">
            Biểu mẫu
          </div>
          <div class="tab" @click="
            tabActive = 'finance';
          financeIndex = 0;
          financeShow = financeList[0];
          " :class="{ active: tabActive == 'finance' }">
            Hạch toán đơn hàng ({{ financeList.length }})
          </div>

          <div v-if="data.remain > 0 && data.remainType == 'CREDIT'" class="tab" @click="tabActive = 'finance-credit'"
            :class="{ active: tabActive == 'finance-credit' }">
            Hạch toán dư nợ
          </div>

          <div v-if="data.remain > 0 && data.remainType == 'EXTRA'" class="tab" @click="tabActive = 'finance-extra'"
            :class="{ active: tabActive == 'finance-extra' }">
            Hạch toán tiền dư
          </div>
        </div>
      </div>

      <div class="p-2 d-md-flex gap-1" v-if="tabActive == 'form'">
        <div class="box mb-2" :style="{ width: $isMobile() ? '100%' : '350px' }">
          <div class="box-item">
            <div v-if="subjectName === 'ORDER'">
              <p>Nhập mã đơn hàng</p>
              <input class="form-control" placeholder="Đơn hàng" :disabled="disabledSelect" v-model="orderCode"
                @keyup.enter="getOrders()" />
            </div>

            <div v-if="subjectName === 'ORDER_LIST'">
              <p>Nhập mã đơn hàng</p>
              <input class="form-control mb-1" placeholder="Đơn hàng" :disabled="disabledSelect" v-model="orderCode"
                @keyup.enter="addOrder()" />

              <button class="btn btn-outline-primary btn-block" @click="addOrder()">
                <i class="fa fa-search" />
                Tìm kiếm và thêm
              </button>
            </div>
            <div v-if="subjectName === 'CUSTOMER'">
              <p>Chọn khách hàng</p>
              <input class="form-control" :disabled="disabledSelect" placeholder="Khách hàng"
                :value="customer && customer.customerName" @focus="selectCustomer()" />
            </div>
            <div v-if="subjectName === 'COMPANY_CUSTOMER'">
              <p>Chọn công ty khách hàng</p>
              <auto-complete :disabled="disabledSelect" placeholder="Cty khách hàng" :list="$customerCompanies"
                v-model="customerCompanyId" @changeOption="getOrders()" />
            </div>

            <div class="d-flex text-right mt-2">
              <p class="flex-1">Tổng nợ chưa trả</p>
              <p class="flex-1">
                {{
                  $formatMoney(
                    orders
                      .filter((e) => e.isCheck)
                      .reduce((a, b) => a + b.remainDebt, 0)
                  )
                }}
              </p>
            </div>

            <div class="d-flex text-right">
              <p class="flex-1">Tổng chưa thanh toán</p>
              <p class="flex-1">
                {{
                  $formatMoney(
                    orders
                      .filter((e) => e.isCheck)
                      .reduce((a, b) => a + b.remainPayment, 0)
                  )
                }}
              </p>
            </div>
            <div class="d-flex text-right align-items-center">
              <p class="flex-1">Thanh toán cho</p>
              <div class="flex-1">
                <options :value="data.isDebtMoney ? 'DEBT' : 'TOTAL'" :list="[
                  { name: 'Công nợ', key: 'DEBT' },
                  { name: 'Tổng đơn', key: 'TOTAL' },
                ]" @input="
                  data.isDebtMoney = $event == 'DEBT';

                fillMoney();
                recount();
                " />
              </div>
            </div>

            <div class="d-flex text-right">
              <p class="flex-1 bold">Tổng cần thanh toán</p>
              <p class="flex-1 bold">
                {{ $formatMoney(data.totalPayment) }}
              </p>
            </div>
          </div>

          <div class="box-item">
            <label>Tổng tiền nhận của khách</label>
            <money-input class="form-control text-right" placeholder="Nhận số tiền nhận" v-model="data.totalMoney"
              @change="recount()" />
          </div>
          <div class="box-item">
            <label>Hình thức thanh toán</label>

            <select class="form-control mb-2" v-model="data.type" @change="recount()">
              <option v-for="item of $paymentTypes" :key="item.id" :value="item.id" :disabled="item.id == 'DEBIT' &&
                orders.find(
                  (a) => a.isCheck && !(a.customers && a.customers.id)
                )
                ">
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="box-item">
            <label>Ngày nhận</label>
            <date-picker class="w100" v-model="data.paymentTime" @change="recount()" format="YYYY-MM-DD" />
          </div>
          <div class="box-item">
            <label>Mô tả</label>
            <textarea class="form-control" v-model="data.description" />
          </div>

          <div class="p-2" v-if="data.remain > 0">
            <p class="mb-2">
              Tiền dư <strong>{{ $formatMoney(data.remain) }}</strong> sẽ được
              chuyển vào
            </p>
            <div>
              <options v-model="data.remainType" :list="[
                // { name: 'Trả lại khách', key: 'RETURN' },
                { name: 'Dư nợ của khách', key: 'CREDIT' },
                { name: 'Doanh thu khác', key: 'EXTRA' },
              ]" />
            </div>
            <div v-if="data.remainType == 'CREDIT'">
              <label>Chọn khách hàng nhận</label>
              <input placeholder="Khách hàng" class="form-control"
                :value="customerCredit && customerCredit.customerName" @focus="selectCustomerCredit()" />
            </div>
          </div>

          <div class="p-2 text-right">
            <button class="btn btn-primary no-break" :disabled="sending ||
              data.totalMoney < data.totalPayment ||
              data.totalPayment <= 0
              " @click="runPayment()">
              <i class="fa fa-save" />
              Lưu thông tin thanh toán
            </button>
          </div>
        </div>
        <div class="flex-1">
          <div class="overflow-auto" :style="{ height: $isMobile() ? 'auto' : 'calc(100lvh - 110px)' }">
            <div v-for="order in orders" :key="order.id">
              <div class="d-flex align-items-center mb-2">
                <div class="box d-md-flex p-2">
                  <div class="flex-fill">
                    <div class="d-flex align-items-center">
                      <check-box :value="order.isCheck" class="mr-2" @input="
                        order.isCheck = !order.isCheck;
                      order.totalPayment = order.remainDebt;
                      recount();
                      " />

                      <div class="bold mr-2">
                        <label>{{ $formatDate(order.invoiceDate) }}</label>
                        <p>{{ order.code }}</p>
                      </div>

                      <span v-if="order.orderStatus == 'FINISHED'" class="m-0 tag tag-sm tag-success-outline">
                        Đã hoàn thành
                      </span>
                      <span v-if="order.orderStatus == 'PROCESSING'" class="m-0 tag tag-sm tag-info-outline">
                        Đang hoàn thành
                      </span>
                      <span v-if="order.orderStatus == 'UNFINISHED'" class="m-0 tag tag-sm tag-basic-outline">
                        Mới tạo
                      </span>

                      <span v-if="order.paymentStatus == 'UNPAID'" class="m-0 ml-2 tag tag-sm tag-basic-outline">
                        Chưa thanh toán
                      </span>

                      <span v-if="order.paymentStatus == 'PARTIAL_PAID'" class="m-0 ml-2 tag tag-sm tag-basic-outline">
                        Thanh toán 1 phần
                      </span>
                    </div>
                    <div class="d-flex mb1">
                      <div class="w-24px" />
                      <div class="flex-1">
                        <label>Chi nhánh</label>
                        <p>{{ order.branchName }}</p>
                      </div>

                      <div class="flex-1">
                        <label>Khách hàng</label>
                        <p>
                          {{
                            order.customers
                              ? order.customers.customerName
                              : "Khách lẻ"
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex mb-2">
                      <div class="w-24px" />
                      <div class="flex-1">
                        <label>Nhân viên phụ trách</label>
                        <p>
                          {{
                            order.personInCharge
                              ? order.personInCharge.fullName
                              : ""
                          }}
                        </p>
                      </div>

                      <div class="flex-1">
                        <label>Kênh bán</label>
                        <p>
                          {{ order.channels }}
                        </p>
                      </div>
                    </div>
                    <div class="d-flex mb1">
                      <div class="w-24px" />
                      <button class="btn btn-outline-primary btn-sm" @click="
                        orders = orders.filter((a) => a.id != order.id);
                      recount();
                      ">
                        <i class="fa fa-chervon-left" />
                        Thanh toán sau
                      </button>
                    </div>
                  </div>
                  <div class="text-right">
                    <p class="d-flex w-100 border-bottom mb-1 pb1">
                      <span class="flex-fill pr-2"> Tổng đơn hàng: </span>
                      <span class="w-200px">
                        {{ $formatMoney(order.grandTotal) }}
                      </span>
                    </p>

                    <p class="d-flex w-100">
                      <span class="flex-fill pr-2"> Đã thanh toán: </span>

                      <span class="w-200px">
                        {{ $formatMoney(order.paidMoney) }}
                      </span>
                    </p>
                    <p class="d-flex w-100 border-bottom">
                      <strong class="flex-fill pr-2">
                        Tổng chưa thanh toán:
                      </strong>
                      <strong class="w-200px">
                        {{ $formatMoney(order.remainPayment) }}
                      </strong>
                    </p>

                    <p class="d-flex w-100">
                      <span class="flex-fill pr-2"> Tổng nợ: </span>
                      <span class="w-200px">
                        {{ $formatMoney(order.totalDebt) }}
                      </span>
                    </p>

                    <p class="d-flex w-100 border-bottom" v-if="order.remainDebt >= 0">
                      <strong class="flex-fill pr-2"> Tổng nợ còn lại: </strong>
                      <strong class="w-200px">
                        {{ $formatMoney(order.remainDebt) }}
                      </strong>
                    </p>
                    <p class="d-flex w-100 border-bottom" v-else>
                      <span class="flex-fill pr-2"> Tổng dư nợ: </span>
                      <span class="w-200px">
                        {{ $formatMoney(order.remainDebt * -1) }}
                      </span>
                    </p>
                    <div class="d-flex w-100 py-1">
                      <strong class="pt-1 flex-fill pr-2"> Thanh toán: </strong>
                      <div class="w-200px">
                        <money-input :max="order.remain" :disabled="sending"
                          class="form-control form-control-sm text-right w-100" placeholder="Khách hàng"
                          v-model="order.totalPayment" @change="
                            order.isCheck = !!order.totalPayment;
                          recount();
                          " />
                        <div class="text-right mt-1">
                          <span v-if="order.totalPayment == order.remainPayment" class="status status-success">
                            Thanh toán toàn bộ
                          </span>

                          <span v-if="
                            order.totalPayment &&
                            order.totalPayment < order.remainPayment
                          " class="status status-warning">
                            Thanh toán 1 phần
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="tabActive == 'finance-credit'" class="p-2">
        <div class="box box-800 mx-auto">
          <form-finance v-model="financeCredit" :key="'financeCredit'" />
        </div>
      </div>
      <div v-if="tabActive == 'finance-extra'" class="p-2">
        <div class="box box-800 mx-auto">
          <form-finance v-model="financeExtra" :key="'financeExtra'" />
        </div>
      </div>
      <div v-if="tabActive == 'finance'" class="p-2">
        <div class="d-flex">
          <div class="box-300 mr-2">
            <div class="box overflow-auto" style="max-height: calc(100lvh - 110px)">
              <div class="p-2">
                <label> Tách phiếu thu theo ...</label>

                <options v-model="splitType" :list="[
                  { name: 'Đơn hàng', key: 'ORDER' },
                  { name: 'Khách hàng', key: 'CUSTOMER' },
                  { name: 'Cty khách hàng', key: 'COMPANY_CUSTOMER' },
                ]" @change="
                  recount();
                financeIndex = 0;
                financeShow = financeList[0];
                " />
              </div>

              <div class="box-item pointer py-1" v-for="(f, index) in financeList" :class="{
                active: index === financeIndex,
              }" :key="f.id" @click="
                financeIndex = index;
              financeShow = f;
              $forceUpdate();
              ">
                <label>{{ f.key }}</label>
                <p class="m-0">
                  {{ f.name }}
                </p>
              </div>
            </div>
          </div>
          <div class="flex-fill" v-if="financeShow">
            <div class="box-800 mx-auto">
              <div class="d-flex">
                <h3 class="font-14 m-0 px-4 py-2 pointer" :class="financeActive == 'ORDER'
                  ? 'bg-primary text-white'
                  : 'bg-white'
                  " @click="financeActive = 'ORDER'">
                  Hạch toán đơn hàng
                </h3>
              </div>
              <div class="box">
                <form-finance v-model="financeShow.finance" :key="'financeObj-' + financeShow.code" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import AutoComplete from "./AutoComplete.vue";
import FormFinance from "./FormFinance.vue";
import { logHistory } from "@/services/history";
export default {
  components: { FormFinance, AutoComplete },
  name: "",
  watch: {},
  data: function () {
    return {
      rawOrders: [],
      disabledSelect: false,
      financeIndex: 1,
      financeActive: "ORDER",
      subjectName: "ORDER",
      customerCompanyId: "",
      customer: null,
      customerId: "",
      loading: false,
      orderCode: "",
      data: {
        remainType: "DEBIT",
        data: "CASH",
        totalMoney: 0,
        remain: 0,
        isDebtMoney: true,
      },
      customerCredit: null,
      isOpen: false,
      orders: [],
      sending: false,
      tabActive: "form",
      financeShow: null,
      financeList: [],
      financeCredit: {},
      financeExtra: {},
      customerName: "",
      splitType: "",
    };
  },
  methods: {
    addOrder() {
      this.getOrders(true);
    },
    selectCustomer() {
      this.$root.selectCustomer
        .openPopup({}, { isSingle: true })
        .then((res) => {
          this.customer = res;
          this.customerId = res.id;
          this.customerCredit = res;
          this.getOrders();
        });
    },
    selectCustomerCredit() {
      this.$root.selectCustomer
        .openPopup({}, { isSingle: true })
        .then((res) => {
          this.customerCredit = res;
        });
    },
    fillMoney() {
      this.orders.forEach((a) => {
        if (a.isCheck) {
          a.totalPayment = this.data.isDebtMoney
            ? a.remainDebt
            : a.remainPayment;
        }
      });
      this.data.totalMoney = this.orders
        .filter((e) => e.isCheck)
        .reduce((a, b) => a + b.totalPayment, 0);
      this.recount();
    },
    async runPayment() {
      if (!(await this.$confirmPopup.open())) {
        return;
      }

      const paymentTime = this.$formatDate(this.data.paymentTime);

      const logs = [];
      const debitCustomer = [];

      const payments = this.financeList.map((a) => {
        var invoices = this.orders
          .filter((o) => a.orderCodes.indexOf(o.code) > -1)
          .map((i) => {
            const item = {
              id: i.id,
              orderStatus: i.orderStatus,
              paymentStatus:
                i.totalPayment === i.remainPayment ? "PAID" : "PARTIAL_PAID",
            };
            item.invoiceCardPayments = [];
            item.invoiceCashPayments = [];
            if (this.data.type == "CASH" || this.data.type == "DEBIT") {
              item.invoiceCashPayments = [
                {
                  currencyUnit: this.$currency,
                  totalCash: i.totalPayment,
                  totalPayment: i.totalPayment,
                  exchangePrice: 1,
                  paymentTime: paymentTime,
                  description: this.data.description,
                },
              ];
            }
            if (this.data.type == "DEBIT") {
              item.invoiceCashPayments = [
                {
                  currencyUnit: this.$currency,
                  totalCash: i.totalPayment,
                  totalPayment: i.totalPayment,
                  exchangePrice: 1,
                  isDebit: true,
                  paymentTime: paymentTime,
                  description: this.data.description,
                },
              ];
            }

            if (this.data.type != "CASH" && this.data.type != "DEBIT") {
              item.invoiceCardPayments = [
                {
                  totalPayment: i.totalPayment,
                  cardType: this.data.type.split("&&")[0],
                  cardNumber: this.data.type.split("&&")[1],
                  paymentTime: paymentTime,
                  description: this.data.description,
                },
              ];
            }

            const orderOld = this.orders.find((a) => a.id == i.id);

            item.totalCash =
              _.sumBy(item.invoiceCashPayments, "totalPayment") +
              orderOld.cashTotal;
            item.totalCard =
              _.sumBy(item.invoiceCardPayments, "totalPayment") +
              orderOld.cardTotal;

            item.paidMoney = item.totalCash + item.totalCard;
            item.remainDebt = i.totalDebt - item.paidMoney;
            item.remainPayment = i.grandTotal - item.paidMoney;

            logs.push({
              type: "UPDATE",
              code: i.code || "",
              model: "ORDER",
              oldData: "",
              newData: `Thêm ${this.$formatMoney(item.paidMoney)}đ qua ${this.data.type
                }`,
              label: "Thanh toán",
            });

            console.log(this.data.type, item);

            if (this.data.type == "DEBIT" && i.customers) {
              debitCustomer.push({
                customerId: i.customers.id,
                transfer: i.customers.customerName,
                receiver: this.$user.fullName,
                creditDate: paymentTime,
                money: item.paidMoney * -1,
                description: "Thanh toán đơn hàng " + i.code,
                branchId: i.branchId,
              });
            }

            return item;
          });
        const elementSale = this.$getElementCode("SALE");
        return {
          invoices,
          incomeData: {
            ...a.finance,
            startDate: this.$formatDate(a.finance.startDate),
            elementCode: elementSale.code,
            elementName: elementSale.name,
            paymentDetailReqs: a.finance.paymentDetailReqs.map((i) => ({
              currencyUnit: i.currencyUnit,
              originalCurrency: i.totalCash,
              exchangePrice: 1,
              totalPayment: i.totalCash * 1,
              isCashPayment: i.type == "CASH",
              cardType: i.type != "CASH" ? i.type.split("&&")[0] : "",
              cardNumber: i.type != "CASH" ? i.type.split("&&")[1] : "",
            })),
          },
          branchId: a.branchId,
        };
      });

      this.sending = true;
      this.$global.isLoading = true;
      try {
        for (let i = 0; i < payments.length; i++) {
          var d = payments[i];
          if (d.invoices && d.invoices.length > 0) {
            await this.$api.paymentInvoice(
              d.invoices,
              d.incomeData,
              d.branchId
            );
          }
        }
        for (let i = 0; i < debitCustomer.length; i++) {
          await this.$api.newCredit(debitCustomer[i], this.$companyId, []);
        }

        if (
          this.data.remain > 0 &&
          this.data.remainType == "CREDIT" &&
          this.customerCredit
        ) {
          const dataSend = {
            customerId: this.customerCredit.id,
            transfer: this.customerCredit.customerName,
            receiver: this.$user.fullName,
            creditDate: paymentTime,
            money: this.data.remain,
            description: "Nhận tiền của khách",
            branchId: this.branchId,
          };
          const element = this.$getElementCode("CUSTOMER_INCOME");

          var incomeDataCredit = {
            ...this.financeCredit,
            startDate: paymentTime,
            elementCode: element.code,
            elementName: element.name,
          };

          await this.$api.newCredit(
            dataSend,
            this.$companyId,
            incomeDataCredit ? [incomeDataCredit] : []
          );
        }

        if (this.data.remain > 0 && this.data.remainType == "EXTRA") {
          const dataInsert = {
            ...this.financeExtra,
            startDate: paymentTime,
            paymentDetailReqs: this.financeExtra.paymentDetailReqs.map((i) => ({
              currencyUnit: i.currencyUnit,
              originalCurrency: i.totalCash,
              exchangePrice: 1,
              totalPayment: i.totalCash * 1,
              isCashPayment: i.type == "CASH",
              cardType: i.type != "CASH" ? i.type.split("&&")[0] : "",
              cardNumber: i.type != "CASH" ? i.type.split("&&")[1] : "",
            })),
          };
          await this.$api.newIncomeExpense(dataInsert, this.branchId);
        }
        if (logs && logs.length) {
          logHistory(logs);
        }
        this._ok({
          totalPayment: "",
        });

        this.closePopup();
      } catch (e) { }
      this.sending = false;
      this.$global.isLoading = false;
    },

    recount() {
      const paymentTime = moment(this.data.paymentTime).toDate();

      this.data.totalPayment = this.orders
        .filter((e) => e.isCheck)
        .reduce((a, b) => a + b.totalPayment, 0);

      this.data.remain = this.data.totalMoney - this.data.totalPayment;

      var orderList = [];
      if (this.splitType == "CUSTOMER") {
        orderList = this.orders.mergeTotal("customerId", "none");
      }
      if (this.splitType == "ORDER") {
        orderList = this.orders.mergeTotal("code", "none");
      }

      if (this.splitType == "COMPANY_CUSTOMER") {
        orderList = this.orders.mergeTotal("customerCompanyId", "none");
      }
      const finances = this.$financeAuto;

      this.financeList = orderList.map((a) => {
        var orders = [];
        var key = "";
        var name = "";

        var transfer;
        if (this.splitType == "CUSTOMER") {
          const customer = a.customers || {
            customerName: "Khách lẻ",
            customerCode: "KHACH_LE",
          };
          orders = this.orders.filter((o) => o.customerId == a.customerId);
          key = customer.customerCode;
          name = customer.customerName;
          transfer = customer;
        }
        if (this.splitType == "ORDER") {
          const customer = a.customers || {
            customerName: "Khách lẻ",
            customerCode: "KHACH_LE",
          };

          orders = this.orders.filter((o) => o.code == a.code);
          key = a.code;
          name = a.code;
          transfer = customer;
        }

        if (this.splitType == "COMPANY_CUSTOMER") {
          const customerCompany =
            a.customers && a.customers.customerCompany
              ? a.customers.customerCompany
              : {
                shortName: "Khách lẻ",
                code: "KHACH_LE",
              };
          orders = this.orders.filter(
            (o) => o.customerCompanyId == a.customerCompanyId
          );
          key = customerCompany.code;
          name = customerCompany.shortName;
          transfer = {
            customerCode: customerCompany.code,
            customerName: customerCompany.shortName,
          };
        }

        const total = orders.reduce((a, b) => a + b.totalPayment, 0);
        const totalTax = orders.reduce(
          (a, b) => a + Math.max(0, b.taxMoney - b.paidMoney),
          0
        );

        const mapKey = {
          "SALE:TAX_CASH":
            this.data.type === "CASH" || this.data.type == "DEBIT"
              ? totalTax
              : 0,
          "SALE:TAX_CARD": this.data.type !== "CASH" ? totalTax : 0,

          "SALE:TOTAL_CASH_DEBT":
            this.data.type == "CASH" ? total - totalTax : 0,
          "SALE:TOTAL_DEBIT_DEBT":
            this.data.type == "DEBIT" ? total - totalTax : 0,
          "SALE:TOTAL_CARD_DEBT":
            this.data.type != "CASH" && this.data.type != "DEBIT"
              ? total - totalTax
              : 0,
        };

        var paymentDetailReqs = [
          {
            currencyUnit: this.$currency,
            originalCurrency: total,
            totalCash: total,
            exchangePrice: 1,
            totalPayment: total,
            type: this.data.type,
          },
        ];

        return {
          branchId: a.branchId,
          code: this.splitType + "-" + a.code,
          key,
          orderCodes: orders.map((a) => a.code),
          name,
          finance: {
            receiver: this.$user.fullName,
            receiverCode: this.$user.employeeCode,
            receiverRepresent: this.$user.fullName,
            address: transfer.address,
            transfer: transfer.customerName,
            transferCode: transfer.customerCode,
            transferRepresent: transfer.customerName,
            totalPayment: total,
            startDate: paymentTime,
            accountantType: "INCOME",
            paymentDetailReqs,
            description:
              "[AUTO] Nhận thanh toán cho đơn hàng " +
              orders.map((a) => a.code).join(", "),
            paymentReqs: finances
              .map((a) => {
                return {
                  receiver: this.$user.fullName,
                  receiverCode: this.$user.employeeCode,
                  transfer: transfer.customerName,
                  transferCode: transfer.customerCode,
                  debtAccId: a.debt.id,
                  availableAccId: a.credit.id,
                  totalPayment: mapKey[a.keyPayment] || 0,
                };
              })
              .filter((a) => a.totalPayment > 0),
          },
        };
      });
      const mapKeyCredit = {
        "CUSTOMER_INCOME:TOTAL_CASH":
          this.data.remainType == "CREDIT" &&
            (this.data.type == "CASH" || this.data.type == "DEBIT")
            ? this.data.remain
            : 0,
        "CUSTOMER_INCOME:TOTAL_CARD":
          this.data.remainType == "CREDIT" &&
            this.data.type != "CASH" &&
            this.data.type != "DEBIT"
            ? this.data.remain
            : 0,
      };

      const mapKeyExtra = {
        "SALE:EXTRA_CASH":
          this.data.remainType == "EXTRA" &&
            (this.data.type == "CASH" || this.data.type == "DEBIT")
            ? this.data.remain
            : 0,
        "SALE:EXTRA_CARD":
          this.data.remainType == "EXTRA" &&
            this.data.type != "CASH" &&
            this.data.type != "DEBIT"
            ? this.data.remain
            : 0,
      };

      const paymentDetailReqs = [
        {
          currencyUnit: this.$currency,
          exchangePrice: 1,
          originalCurrency: this.data.remain,
          totalPayment: this.data.remain,
          isCashPayment: this.data.type == "CASH" || this.data.type == "DEBIT",
        },
      ];

      const customer = (this.orders[0] && this.orders[0].customers) || {
        customerName: "Khách lẻ",
        customerCode: "KHACH_LE",
      };

      if (this.data.remainType == "CREDIT") {
        this.financeCredit = {
          receiver: this.$user.fullName,
          receiverCode: this.$user.employeeCode,
          receiverRepresent: this.$user.fullName,
          address: customer.address,
          transfer: customer.customerName,
          transferCode: customer.customerCode,
          transferRepresent: customer.customerName,
          startDate: paymentTime,
          accountantType: "INCOME",
          paymentDetailReqs,
          description: "[AUTO] Nhận tiền của khách hàng",

          totalPayment: this.data.remain,
          paymentReqs: finances
            .map((a) => {
              return {
                receiver: this.$user.fullName,
                receiverCode: this.$user.employeeCode,
                transfer: customer.customerName,
                transferCode: customer.customerCode,
                debtAccId: a.debt.id,
                availableAccId: a.credit.id,
                totalPayment: mapKeyCredit[a.keyPayment] || 0,
              };
            })
            .filter((a) => a.totalPayment > 0),
        };
      }

      if (this.data.remainType == "EXTRA") {
        this.financeExtra = {
          receiver: this.$user.fullName,
          receiverCode: this.$user.employeeCode,
          receiverRepresent: this.$user.fullName,
          address: customer.address,
          transfer: customer.customerName,
          transferCode: customer.customerCode,
          transferRepresent: customer.customerName,
          startDate: paymentTime,
          accountantType: "INCOME",
          totalPayment: this.data.remain,
          paymentDetailReqs,
          description: "[AUTO] Nhận tiền dư từ khách hàng",
          paymentReqs: finances
            .map((a) => {
              return {
                receiver: this.$user.fullName,
                receiverCode: this.$user.employeeCode,
                transfer: customer.customerName,
                transferCode: customer.customerCode,
                debtAccId: a.debt.id,
                availableAccId: a.credit.id,
                totalPayment: mapKeyExtra[a.keyPayment] || 0,
              };
            })
            .filter((a) => a.totalPayment > 0),
        };
      }

      this.$forceUpdate();
    },
    async getOrders(isAddMore) {
      var where;

      if (this.customerId && this.subjectName == "CUSTOMER") {
        where = {
          "customers.id": this.customerId,
        };
      }
      if (this.customerCompanyId && this.subjectName == "COMPANY_CUSTOMER") {
        where = {
          "customers.customerCompany.id": this.customerCompanyId,
        };
      }
      if (this.orderCode && this.subjectName == "ORDER_LIST") {
        where = {
          code_like: this.orderCode,
        };
      }
      if (this.id_in && this.subjectName == "ORDER_LIST") {
        where = {
          id_in: this.id_in,
        };
      }
      if (this.orderCode && this.subjectName == "ORDER") {
        where = {
          code: this.orderCode,
        };
      }

      if (!where) {
        return;
      }
      where.remainPayment_gt = 0;
      this.$global.setLoading(true);
      var res;
      if (this.branchId) {
        res = await this.$api.getInvoices(
          this.branchId,
          {
            ...where,
          },
          "id_asc",
          1000,
          0
        );
      } else {
        res = await this.$api.getInvoicesSummaryAdmin(
          {
            companyId: this.$companyId,
            branchIds: this.$branchList.map((i) => i.branches.id),
            ...where,
          },
          1000,
          0,
          "id_asc"
        );
      }

      this.$global.setLoading(false);
      const newOrder = res.list.map((a) => {
        return {
          ...a,
          isCheck: true,
          customerId: a.customers ? a.customers.id : -1,
          customerCompanyId:
            a.customers && a.customers.customerCompany
              ? a.customers.customerCompany.id
              : -1,
          totalPayment: a.remainPayment,
        };
      });
      if (isAddMore) {
        this.orders = this.orders.concat(newOrder);
      } else {
        this.orders = newOrder;
      }
      this.orders = _.uniqBy(this.orders, "id");
      this.fillMoney();
      this.id_in = undefined;
    },
    openPopup(where = {}, branchId, disabledSelect) {
      this.sending = false;
      this.isOpen = true;
      this.totalMoney = 0;
      this.tabActive = "form";
      this.disabledSelect = disabledSelect || false;
      this.data = {
        remainType: "CREDIT",
        type: "CASH",
        cardNumber: "",
        totalMoney: 0,
        remain: 0,
        isDebtMoney: true,
        paymentTime: moment().toDate(),
      };
      this.financeList = [];
      this.orderCode = "";
      this.customerCompanyId = "";
      this.subjectName = "ORDER";
      this.splitType = "ORDER";
      this.branchId = branchId || this.$branchId;

      if (where.customerId) {
        this.customerId = where.customerId;
        this.customerName = where.customerName;
        this.subjectName = "CUSTOMER";
        this.splitType = "CUSTOMER";
      }
      if (where.code) {
        this.orderCode = where.code;
        this.splitType = "ORDER";
        this.subjectName = "ORDER";
      }
      if (where.id_in) {
        this.id_in = where.id_in;
        this.splitType = "ORDER";
        this.subjectName = "ORDER_LIST";
      }

      if (where.customerCompanyId) {
        this.customerCompanyId = where.customerCompanyId;
        this.splitType = "COMPANY_CUSTOMER";
        this.subjectName = "COMPANY_CUSTOMER";
      }

      this.getOrders();
      return new Promise((resolve, reject) => {
        this._ok = resolve;
        this._close = reject;
      });
    },
    closePopup() {
      this._ok();
      this.isOpen = false;
    },
  },
};
</script>
