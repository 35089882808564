var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quick-view",class:_vm.isOpen ? ' open' : '',on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closePopup()}}},[(_vm.isOpen)?_c('div',{staticClass:"quick-view-content gb-white sm",staticStyle:{"width":"1000px"}},[_c('div',{staticClass:"px-2 pt-2 header-list bg-white"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-2 flex-fill"},[(!_vm.isSingle)?_c('p',{staticClass:"m-0"},[_c('i',{staticClass:"fa fa-users"}),_vm._v(" Đã chọn "),_c('strong',{staticClass:"font-16"},[_vm._v(_vm._s(_vm.listSelecteds.length))]),_vm._v(" khách hàng ")]):_vm._e(),(_vm.isSingle)?_c('h5',{staticClass:"m-0 no-break font-16 bold"},[_vm._v(" Chọn khách hàng ")]):_vm._e()]),_c('button',{staticClass:"btn btn-basic pl-3 pr-3 mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.closePopup()}}},[_vm._v(" Tắt ")]),(!_vm.isSingle)?_c('button',{staticClass:"btn btn-primary pl-3 pr-3",attrs:{"type":"submit"},on:{"click":function($event){return _vm.save()}}},[_c('span',{staticClass:"d-none d-sm-inline-block text-white ml-1"},[_vm._v(" "+_vm._s(_vm.$t("common.done"))+" ")]),_c('i',{staticClass:"fa fa-arrow-right"})]):_vm._e()]),_c('div',{staticClass:"tabs-border flex flex-row align-items-stretch mt-0"},[_c('div',{staticClass:"tab",class:{ active: _vm.tabActive == 'mine' },on:{"click":function($event){_vm.tabActive = 'mine';
            _vm.updateParams();}}},[_vm._v(" Khách hàng của tôi ")]),_c('div',{staticClass:"tab",class:{ active: _vm.tabActive == 'all' },on:{"click":function($event){_vm.tabActive = 'all';
            _vm.updateParams();}}},[_vm._v(" Tất cả khách hàng ")]),(!_vm.isSingle)?_c('div',{staticClass:"tab",class:{ active: _vm.tabActive == 'selected' },on:{"click":function($event){_vm.tabActive = 'selected'}}},[_vm._v(" Khách hàng đã chọn ("+_vm._s(_vm.listSelecteds.length)+") ")]):_vm._e()])]),(_vm.tabActive == 'selected')?_c('DataTableLocal',{attrs:{"fields":_vm.fields,"data":_vm.listSelecteds,"rowActions":[],"headerRenderIndex":_vm.isSingle ? 0 : 1,"name":"chon-khach-hang","noExport":true,"filterLocal":true}}):_vm._e(),(_vm.tabActive == 'mine' || _vm.tabActive == 'all')?_c('DataTable',{ref:"dataTable",attrs:{"renderHeaderMobile":_vm.renderHeaderMobile,"fields":_vm.isSingle
          ? _vm.fields
          : [
              {
                key: '_',
                checkbox: function (r) { return _vm.listSelecteds.map(function (a) { return a.id; }).indexOf(r.id) > -1; },
                click: function (r) { return _vm.choose(r); },
              } ].concat(_vm.fields),"rowActions":[],"headerRenderIndex":_vm.isSingle ? 0 : 1,"nameFunction":"getCustomers","paramsQuery":_vm.params,"keySearch":"customerName","height":"calc(100lvh - 127px)","exactCode":"customerCode","name":"chon-khach-hang","noExport":true,"filterLocal":true}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }